.history-item-editor {
  padding: .375rem .75rem;
}

.history-item-editor:hover {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.item-source {
  text-align: right;
  padding: 3.75rem;
}

.item-source span {
  background: #f5f5f5;
  border-radius: 5px;
  padding: 0.2em;
}
