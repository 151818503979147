:root {
  /* official branding
   * https://docs.google.com/presentation/d/10h9PAM_0bVTNEpjHloAyvlzDMlKuG-xMrqGmFzGM3_M/edit#slide=id.g26c090d9188_1_31
   */
  --aa-violet: #8800ff; /* electric violet */
  --aa-pink: #ff216e; /* hot pink */
  --aa-black: #000;
  --aa-yellow: #e3fc26; /* chartreuse yellow */
  --aa-beige: #ededd9; /* white rock */
  --aa-blue: #0600ff;
  --aa-turquoise: #00e1ff; /* turquoise blue */

  /* modified colors */
  --aa-light-black: #1c1c1c;
  --aa-light-beige: #fef8f1;
}

body {
  background: var(--aa-light-beige);
  height: 100%;
}

#root > div {
  background: white;
  min-height: 100vh;
  padding: 0;
  border-left: 2px solid var(--aa-light-black);
  border-right: 2px solid var(--aa-light-black);
}

#root > .container > * {
    padding-left: var(--bs-gutter-x);
    padding-right: var(--bs-gutter-x);
}

header#admin-header {
  background: var(--aa-light-black);
  color: var(--aa-yellow);
}

header#admin-header a {
  color: var(--aa-yellow);
  text-decoration: none;
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
